/* General styles */
body {
  margin: 0;
  font-family: Arial, sans-serif;
}

.app-container {
  display: flex;
  justify-content: space-between;
  padding: 20px;
  min-height: 100vh;
  flex-wrap: wrap; /* Allow wrapping on smaller screens */
}

.sidebar {
  flex: 0 0 250px; /* Fixed width for the sidebar */
  padding-right: 20px;
}

.news-grid-container {
  flex: 1;
  max-width: 1000px;
  margin: 0 20px;
}

.weather-card {
  flex: 0 0 250px; /* Fixed width for the weather card */
  padding-left: 20px;
}

/* Dark Mode Styles */
body.dark-mode {
  background-color: #121212;
  color: white;
}

/* Light Mode Styles */
body.light-mode {
  background-color: #ffffff;
  color: #333;
}

/* Responsive styles for smaller screens */
@media (max-width: 768px) {
  .app-container {
    flex-direction: column; /* Stack the elements vertically */
    padding: 10px;
    
  }

  .weather-sidebar {
    position: fixed;
    right: 50px;
    top: 120px;
    width: 350px;
    z-index: 1000;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    padding: 20px;
    background-color: #212429; /* Updated container background */
    max-height: calc(100vh - 180px);
    overflow-y: auto;
    margin-right: 20px;
  }

  .sidebar, .weather-card {
    flex: 0 0 100%; /* Full width on small screens */
    padding: 10px 0;
  }

  .news-grid-container {
    flex: 1;
    max-width: 100%;
    margin: 10px 0;
  }
}

@media (max-width: 480px) {
  .sidebar, .weather-card {
    flex: 0 0 100%; /* Full width on very small screens */
    padding: 5px 0;
  }

  .news-grid-container {
    margin: 5px 0;
  }
}

/* Light Mode Defaults */
:root {
  --background-color-light: #fff;
  --text-color-light: #212529;
  --button-background-light: #054b29;
  --button-color-light: #fff;
  --button-hover-background-light: #5ab872;
  --card-background-light: #e9ecef; 
  --navbar-background-light: #fff;
  --navbar-text-color-light: #212529;
  --search-bar-input: #fff;
  --search-bar-input-border: #fff;
  --search-bar-input-text: #212529;
  --pagination-button-background-light: #054b29;
  --pagination-button-hover-background-light: #5ab872;
  --pagination-button-text-color-light: #fff;
  --login-button-background-light: #054b29;
  --login-button-color-light: #fff;
  --login-button-hover-background-light: #5ab872;
  --toggle-background-light: #054b29;
  --toggle-color-light: #fff;
}

/* Dark Mode Defaults */
:root {
  --background-color-dark: #343a40;
  --text-color-dark: #f8f9fa;
  --button-background-dark: #054b29;
  --button-color-dark: #fff;
  --button-hover-background-dark: #5ab872;
  --card-background-dark: #495057;
  --card-text-color-dark: #f8f9fa;
  --navbar-background-dark: #343a40;
  --navbar-text-color-dark: #f8f9fa;
  --search-bar-input: #212429;
  --search-bar-input-border: #4f5253;
  --search-bar-input-text: #f8f9fa;
  --pagination-button-background-dark: #054b29;
  --pagination-button-hover-background-dark: #5ab872;
  --pagination-button-text-color-dark: #fff;
  --login-button-background-dark: #054b29;
  --login-button-color-dark: #fff;
  --login-button-hover-background-dark: #5ab872;
  --toggle-background-dark: #054b29;
  --toggle-color-dark: #fff;
}

/* Applying the themes */
body {
  background-color: var(--background-color-light);
  color: var(--text-color-light);
}

body.dark-mode {
  background-color: var(--background-color-dark);
  color: var(--text-color-dark);
}

.light-mode {
  background-color: var(--background-color-light);
  color: var(--text-color-light);
}

.dark-mode {
  background-color: var(--background-color-dark);
  color: var(--text-color-dark);
}

.navbar {
  background-color: var(--navbar-background-light);
  color: var(--navbar-text-color-light);
}

body.dark-mode .navbar {
  background-color: var(--navbar-background-dark);
  color: var(--navbar-text-color-dark);
}

/* Custom CSS for the search bar */
.custom-search-bar {
  max-width: 500px;
  width: 100%;
}

.custom-search-input {
  border-radius: 25px 0 0 25px;
  padding: 0.5rem 1rem;
  width: 500px;
  background-color: var(--search-bar-input);
  border: 1px solid var(--search-bar-input-border);
  color: var(--search-bar-input-text);
}

.custom-search-button {
  border-radius: 0 25px 25px 0;
  background-color: var(--button-background-light);
  color: var(--button-color-light);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem 1.5rem;
}

.custom-search-button:hover {
  background-color: var(--button-hover-background-light);
  color: var(--button-color-light);
}

.custom-search-button i {
  font-size: 1.2rem;
}

/* Sidebar Styling */
.sidebar-container {
  position: fixed;
  left: 50px;
  top: 120px;
  width: 350px;
  z-index: 1000;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 20px;
  background-color: var(--card-background-dark);
  max-height: calc(100vh - 180px);
  overflow-y: auto;
  margin-right: 20px;
}

.text-center {
  color: #000;
}

.form-label {
  color: #fff;
}

.sidebar-button-first {
  width: 100%;
  padding: 10px;
  font-size: 1rem;
  text-align: center;
  border-radius: 10px;
  background-color: var(--button-background-light);
  border: var(--button-background-light);
}

.sidebar-button-second {
  width: 100%;
  padding: 10px;
  font-size: 1rem;
  text-align: center;
  border-radius: 10px;
  background-color: var(--card-background-light);
  color: var(--button-background-light);
  border: 1px solid var(--button-background-light);
}

.sidebar-button-first:hover, 
.sidebar-button-second:hover {
  background-color: var(--button-hover-background-light);
}

/* Weather Sidebar Styling */
.weather-sidebar {
  position: fixed;
  right: 50px;
  top: 120px;
  width: 350px;
  z-index: 1000;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 20px;
  background-color: #212429; /* Updated container background */
  max-height: calc(100vh - 180px);
  overflow-y: auto;
  margin-right: 20px;
}

@media (max-width: 991px) {
  .weather-sidebar {
    position: unset !important;
    top: 120px;
    width: 100%;
    z-index: 1000;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    padding: 20px;
    background-color: #212429; /* Updated container background */
    max-height: calc(100vh - 180px);
    overflow-y: auto;
    margin-right: 20px;
  }

  .date-container {
    text-align: center !important;
  }


  /* Sidebar Styling */
.sidebar-container {
  position: sticky;
  left: 50px;
  top: 120px;
  width: 100%;
  z-index: 1000;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 20px;
  background-color: var(--card-background-dark);
  max-height: calc(100vh - 180px);
  overflow-y: auto;
  margin-right: 20px;
}
}


.weather-sidebar .card {
  width: 100%;
  background-color: #054b29;
}

.weather-sidebar .card-body {
  padding: 15px;
}

.weather-sidebar .card-body p {
  padding: 15px;
  color: #fff !important;
  font-size: large;
}

.weather-sidebar .card-title {
  font-size: 1.5rem;
  font-weight: bold;
  text-align: center;
  color: #fff;
}

.card-title {
  color: #000;
}

.card-text {
  color: #000 !important;
}

.weather-sidebar .card-subtitle {
  font-size: 1rem;
  color: var(--button-hover-background-light);
}

.weather-sidebar .card-text {
  font-size: 0.9rem;
  color: var(--card-text-color-light);
}

/* News Grid Styling */
.news-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px;
}

.news-container .card {
  width: 100%;
}

.news-container .card-img-top {
  object-fit: cover;
}

.news-container .mb-4 {
  margin-bottom: 20px;
}

.news-grid-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 300px !important;
}

.pagination-container {
  text-align: center;
  margin-top: 20px;
}

.pagination-container button {
  margin: 0 10px;
  background-color: var(--pagination-button-background-light) !important;
  color: var(--pagination-button-text-color-light) !important;
  border: 1px solid var(--pagination-button-background-light) !important;
  padding: 10px 20px;
  border-radius: 5px;
}

.pagination-container button:hover {
  background-color: var(--pagination-button-hover-background-light) !important;
}

/* Login Button */
.login-button {
  background-color: var(--login-button-background-light) !important;
  color: var(--login-button-color-light) !important;
  border: 1px solid var(--login-button-background-light) !important;
}

.login-button:hover {
  background-color: var(--login-button-hover-background-light) !important;
}

/* Toggle Button Styling */
.toggle-button {
  background-color: var(--toggle-background-light);
  color: var(--toggle-color-light);
  border-radius: 25px;
  padding: 10px 20px;
}

.toggle-button:hover {
  background-color: var(--toggle-background-dark);
  color: var(--toggle-color-dark);
}

.footer {
  background-color: #353a40;
  padding: 20px 0;
  margin-top: 30px;
}

.footer p {
  color: #fff;
  padding: 10px;
  margin-top: 10px;
}

.footer-link {
  color: #5ab872;
  text-decoration: none;
  margin: 0 10px;
}


.footer-link:hover {
  text-decoration: underline;
}

.domain-select {
  width: 150px; 
}

.btn:hover {
  background-color: #5ab872 !important;
}

/* Header.css */

/* Mobile view adjustments */
@media (max-width: 767px) {
    .navbar-nav {
      text-align: center;
    }
  
    .navbar-toggler {
      display: block; /* Ensure toggle button is visible on mobile */
    }
  
    .navbar-collapse {
      text-align: center;
    }
  
    .navbar-nav .nav-item {
      margin: 10px 0; /* Add spacing between menu items on mobile */
    }
  
    .custom-mega-dropdown .dropdown-menu {
      width: 100%; /* Make dropdown menu full width */
    }
  
    .navbar-brand img {
      height: 40px; /* Adjust logo size for mobile */
    }
  
    /* Hide the login and dark mode toggle on smaller screens */
    .navbar-nav .nav-item button {
      display: none;
    }
  }
  
  